<script>
import api from '@/command/api'
import DetailFormGroup from '../../../components/DetailFormGroup'
import apiTool from '@/command/apiTool'
import { getAction } from '@/command/netTool'
import { suitNum } from '@/utils/textFile'
import CardIconLabelLocal from '../../../components/CardIconLabelLocal'
import moment from 'moment'
import { message } from 'ant-design-vue'

export default {
  name: 'farmingDetail',
  data() {
    return {
      ...api.command.getState(),
      labelGroup: [],
      // 0 采摘 1 垂钓
      type: '0',
      detail: {
        salePrice: null,
        vipPrice: null,
        availableRules: ['0', '1'],
        canBuy: '1',
        recommend: '0',
      },
      isShowSnapshot: false,
      storeData: [],
    }
  },
  watch: {
    'detail.mainBodyId'(a, b) {
      if (a) {
        this.getBaseDataInit(a)
        this.getMainBody(a)
      }
    },
  },
  mounted() {
    const { id } = this.$route.query
    this.type = this.$route.query.type
    let code = this.type == 0 ? 'farm_experience_pick' : 'farming_experience_fish'
    if (id) {
      api.command.getDetail
        .call(this, {
          url: `/goods/farmFarmingExperience/detail?id=${id}`,
        })
        .then((result) => {
          this.detail.availableRules = result.availableRules ? result.availableRules.split(',') : []
          this.detail.suitNum = result.suitNum + ''
          this.type = this.detail.type
          api.command.getLabelCodeGroup.call(this, {
            url: '/api/base/farmLabelData/getByCodeSelf',
            params: {
              id: id,
              codes: [
                {
                  key: this.detail.type == 0 ? 'farm_experience_pick' : 'farming_experience_fish',
                },
              ],
            },
          })
          this.$forceUpdate()
        })
    } else {
      api.command.getLabelCodeGroup.call(this, {
        url: '/api/base/farmLabelData/getByCodeSelf',
        params: {
          codes: [
            {
              key: code,
            },
          ],
        },
      })
    }

    this.storeSearch()
  },
  methods: {
    getMainBody(id) {
      getAction(`/api/baseMonitor/queryBaseMonitorList?shopId=${id}`).then((e) => {
        this.isShowSnapshot = e.data.length > 0 ? true : false
      })
    },
    async storeSearch(value = '') {
      const searchResult = await api.command.search.call(this, {
        url: `/api/commodity/farmMainBody/list?mainBodyName=${value}`,
      })
      this.storeData = searchResult.map((el) => {
        return {
          ...el,
          name: el.mainBodyName,
          value: el.id,
        }
      })
    },
    getType() {
      return [
        {
          name: '适用人数',
          type: 'select',
          cols: 6,
          gutter: 10,
          key: 'suitNum',
          defaultValue: '1',
          typeData: suitNum(),
          rules: [
            {
              required: true,
              type: 'string',
            },
          ],
        },
        {
          name: '剩余库存',
          type: 'inputNumber',
          cols: 6,
          key: 'stockNum',
          rules: [
            {
              required: true,
              type: 'number',
            },
          ],
          props: {
            min: 0,
          },
        },
        {
          name: '预约',
          type: 'select',
          cols: 6,
          gutter: 10,
          key: 'subscribe',
          defaultValue: '0',
          typeData: [
            {
              name: '免预约',
              value: '0',
            },
            {
              name: '电话预约',
              value: '1',
            },
          ],
          rules: [
            {
              required: true,
              type: 'string',
            },
          ],
        },
        {
          name: '退款',
          type: 'select',
          cols: 6,
          gutter: 10,
          key: 'refundExplain',
          defaultValue: '0',
          typeData: [
            {
              name: '随时退',
              value: '0',
            },
            {
              name: '不可退',
              value: '1',
            },
            {
              name: '有效期可退',
              value: '2',
            },
          ],
          rules: [
            {
              required: true,
              type: 'string',
            },
          ],
        },
        // {
        //   name: 'Banner选择',
        //   type: 'radioButton',
        //   key: 'bannerType',
        //   cols: 12,
        //   defaultValue: '0',
        //   typeData: [
        //     {
        //       name: 'banner图片',
        //       value: '0',
        //       display: true,
        //     },
        //     {
        //       name: '视频地址',
        //       value: '2',
        //       display: true,
        //     },
        //     {
        //       name: '数字基地监控视频',
        //       value: '1',
        //       display: this.isShowSnapshot,
        //     },
        //   ].filter((e) => e.display),
        //   rules: [
        //     {
        //       required: true,
        //       type: 'string',
        //     },
        //   ],
        // },
        {
          name: '开放购买（默认选择是）',
          type: 'radioButton',
          key: 'canBuy',
          cols: 6,
          typeData: [
            {
              name: '是',
              value: '1',
            },
            {
              name: '否',
              value: '0',
            },
          ],
          rules: [
            {
              required: true,
            },
          ],
        },
        {
          name: '周边推荐（默认选择否）',
          type: 'radioButton',
          key: 'recommend',
          cols: 6,
          typeData: [
            {
              name: '是',
              value: '1',
            },
            {
              name: '否',
              value: '0',
            },
          ],
          rules: [
            {
              required: true,
            },
          ],
        },
        {
          type: 'row',
          cols: 6,
          children: [
            {
              name: '有效期',
              type: 'rangePicker',
              keys: ['startDate', 'endDate'],
              cols: 24,
              rules: [
                {
                  required: true,
                },
              ],
            },
          ],
        },
        {
          name: '可用规则',
          type: 'checkBoxButton',
          key: 'availableRules',
          cols: 6,
          typeData: [
            {
              name: '法定节假日可用',
              value: '0',
            },
            {
              name: '周末可用',
              value: '1',
            },
          ],
          // rules: [
          //   {
          //     required: true,
          //     type: 'array',
          //     message: '请选择可用规则'
          //   }
          // ]
        },
        {
          key: 'tips',
          align: 'left',
          name: '温馨提示',
          type: 'textArea',
          cols: 12,
          maxLength: 1000,
          minHeight: '100px',
          showCount: true,
        },
      ]
    },
    getForm1() {
      return {
        title: '基本信息',
        type: 'cardForm',
        data: [
          {
            name: '名称(30字符内)',
            type: 'input',
            cols: 12,
            key: 'name',
            props: {
              maxLength: 30,
            },
            rules: [
              {
                required: true,
              },
            ],
          },
          {
            name: '关联店铺',
            type: 'dropDownInput',
            cols: 12,
            key: 'mainBodyName',
            onInputSearch: this.storeSearch,
            valueKey: 'mainBodyId',
            inputProps: {
              placeholder: '搜索店铺',
              addonBefore: <a-icon type="search" />,
            },
            rules: [
              {
                required: true,
              },
            ],
          },
          ...this.getType(),
        ],
      }
    },
    // getFormBox() {
    //   return {
    //     type: 'cardFormBox',
    //     children: [this.getForm2(), this.getForm23()],
    //   }
    // },
    getForm2() {
      return {
        type: 'cardForm',
        title: () => (
          <div>
            <span style={{ color: '#f5222d' }}>*</span>Banner（限制10张）
          </div>
        ),
        data: [
          {
            type: 'uploadDraggable',
            maxLength: 10,
            key: 'img',
            cols: 24,
            multiple: true,
            rules: [
              {
                required: true,
                message: '请上传图片',
                type: 'string',
              },
            ],
          },
        ],
      }
    },
    getForm22() {
      return {
        type: 'cardForm',
        title: () => (
          <div>
            预览图尺寸355*320（限制10张）
          </div>
        ),
        data: [
          {
            type: 'uploadDraggable',
            maxLength: 1,
            key: 'previewUrl',
            cols: 24,
          },
        ],
      }
    },
    getForm23() {
      return {
        type: 'cardForm',
        title: '宣传视频',
        data: [
          {
            type: 'iconUploadVideo',
            cols: 12,
            typeData: [
              {
                maxLength: 1,
                key: 'videoUrl',
                desc: '视频上传尺寸<20M <br /> 支持格式.mov/.mp4/.wmv/.avi',
              },
            ],
          },
        ],
      }
    },
    getForm2Copy() {
      return {
        type: 'cardForm',
        title: '数字基地监控视频',
        data: [this.isShowSnapshot ? this.getBaseDataInit(this.detail.mainBodyId) : {}],
      }
    },
    getBaseDataInit(shopId = null) {
      return {
        type: 'baseUpload',
        key: 'monitorCode',
        maxLength: 1,
        cols: 24,
        shopId: shopId,
        setValue: this.detail.monitorCode,
        setFirm: false,
      }
    },
    getForm3() {
      let that = this
      return {
        type: 'table',
        showFootButton: false,
        showRowSelect: false,
        showPagination: false,
        columns: [
          {
            dataIndex: 'salePrice',
            align: 'left',
            title: '现价',
            width: '15%',
            customRender: function (text, records) {
              const onInput = (data) => {
                if (data.target.value) {
                  if (!/[0-9\.]/.test(data.target.value)) {
                    data.target.value = null
                    that.$message.warning('请输入数字！')
                    return false
                  } else {
                    that.detail.salePrice = data.target.value
                  }
                } else {
                  that.detail.salePrice = ''
                }
              }
              return (
                <a-input
                  disabled={that.detail.canBuy == '0'}
                  type="number"
                  placeholder="价格"
                  addon-before="￥"
                  onChange={onInput}
                  value={text}
                />
              )
            },
          },
          {
            dataIndex: 'vipPrice',
            align: 'left',
            title: '会员最低价',
            width: '15%',
            customRender: function (text, records) {
              const onInput = (data) => {
                if (data.target.value) {
                  if (Number(data.target.value) > Number(that.detail.salePrice)) {
                    data.target.value = null
                    that.$message.warning('会员价应低于现价！')
                    return false
                  } else if (!/[0-9\.]/.test(data.target.value)) {
                    data.target.value = null
                    that.$message.warning('请输入数字！')
                    return false
                  } else {
                    that.detail.vipPrice = data.target.value
                  }
                } else {
                  that.detail.vipPrice = ''
                }
              }
              return (
                <a-input
                  disabled={that.detail.canBuy == '0'}
                  type="number"
                  placeholder="会员价（不填没有会员价）"
                  addon-before="￥"
                  onInput={onInput}
                  value={text}
                />
              )
            },
          },
          {
            dataIndex: 'v1',
            align: 'center',
            title: 'V1',
            width: 50,
          },
          {
            dataIndex: 'v2',
            align: 'center',
            title: 'V2',
            width: 50,
          },
          {
            dataIndex: 'v3',
            align: 'center',
            title: 'V3',
            width: 50,
          },
          {
            dataIndex: 'v4',
            align: 'center',
            title: 'V4',
            width: 50,
          },
          {
            dataIndex: 'v5',
            align: 'center',
            title: 'V5',
            width: 50,
          },
          {
            dataIndex: 'v6',
            align: 'center',
            title: 'V6',
            width: 50,
          },
        ],
        dataSource: [
          {
            salePrice: that.detail.salePrice,
            vipPrice: that.detail.vipPrice,
            ...apiTool.getVipPrice(this.detail),
          },
        ],
      }
    },
    getLabelForm(index) {
      return [
        {
          name: '标签名称',
          type: 'input',
          key: 'labelName',
        },
        {
          name: '标签类型',
          type: 'radioButton',
          key: 'publicLabel',
          typeData: [
            {
              name: '店铺私有',
              value: '2',
            },
            {
              name: '公用标签库',
              value: '1',
            },
          ],
        },
      ].filter((e) => e)
    },
    getForm4() {
      return {
        type: 'row',
        children: (this.labelGroup || []).map((e, i) => {
          return (
            <CardIconLabelLocal
              data={this.getLabelForm(i)}
              title={e.labelTypeName}
              pubBool={false}
              cols={24}
              style={{
                marginBottom: '10px',
                minHeight: '200px',
              }}
              icons={e.list}
            />
          )
        }),
      }
    },
    getForm6() {
      return {
        type: 'cardForm',
        cols: 24,
        data: [
          {
            type: 'rich',
            key: 'richText',
            cols: 24,
          },
        ],
      }
    },
    getString(str) {
      // eslint-disable-next-line prettier/prettier
      return str
        ? str
            .split(',')
            .filter((e) => e)
            .join(',')
        : ''
    },
    getFoot() {
      let that = this
      const left = [
        {
          name: '提交',
          type: 'primary',
          loading: this.loading,
          sumbit: true,
          onClick: (data) => {
            // startDate

            let upDown = this.detail.id ? this.detail.upDown : '0'

            let goodsTypeName = document.getElementsByClassName('ant-select-selection-selected-value')[0].innerHTML

            let arr = []
            that.labelGroup.forEach((item) =>
              item.list.forEach((e) => {
                e['labelTypeCode'] = item.labelTypeCode
                e['labelTypeId'] = item.id
                e['labelTypeName'] = item.labelTypeName
              })
            )

            // if (data.availableRules.length == 0) {
            //   this.$message.warning('请选择可用规则！')
            //   return
            // } else {
            //   let arr = []
            //   data.availableRules.forEach(e => {
            //     arr.push(['法定节假日可用', '周末可用'][e])
            //   })
            //   data.availableRulesName = arr.toString()
            // }

            if (data.availableRules.length > 0) {
              let arr = []
              data.availableRules.forEach((e) => {
                arr.push(['法定节假日可用', '周末可用'][e])
              })
              data.availableRulesName = arr.toString()
            } else {
              data.availableRulesName = ''
            }

            if (!data.startDate) {
              message.error('请选择有效期')
              return
            }

            that.labelGroup.map((e) => arr.push(...e.list))
            let obj = Object.assign({
              ...data,
              startDate: moment(data.startDate, 'YYYY-MM-DD').format('YYYY-MM-DD HH:mm:ss'),
              endDate: moment(data.endDate, 'YYYY-MM-DD').format('YYYY-MM-DD HH:mm:ss'),
              availableRules: data.availableRules.toString(),
              upDown: upDown,
              type: this.detail.id ? this.detail.type : this.type,
              farmLabelDataDTOS: arr.map((item) => ({
                ...item,
                czState: item.czState ? 1 : 0,
              })),
            })

            let monitorCode = null
            let monitorImg = null
            if (
              this.detail.monitorCode instanceof Array &&
              this.detail.bannerType == 1 &&
              this.detail.monitorCode.length > 0
            ) {
              monitorCode = (
                this.detail.monitorCode.find((e) => e.select) || {
                  monitorCode: '',
                }
              ).monitorCode
              monitorImg = (
                this.detail.monitorCode.find((e) => e.select) || {
                  monitorImg: '',
                }
              ).monitorImg
            }

            api.command[this.detail.id ? 'edit' : 'create']
              .call(this, {
                url: '/goods/farmFarmingExperience',
                params: {
                  ...obj,
                  anglingLabel: this.getString(obj.anglingLabel),
                  monitorCode: monitorCode,
                  monitorImg: monitorImg,
                  goodsTypeName: goodsTypeName,
                },
                isPost: false,
              })
              .then((result) => {
                setTimeout(() => {
                  this.$router.back()
                }, 500)
              })
          },
        },
        {
          name: '取消',
          type: 'default',
          onClick: () => {
            setTimeout(() => {
              this.$router.back()
            }, 500)
          },
        },
      ]
      const right = [
        {
          name: ` ${this.detail.upDown == 0 ? '下架' : '上架'} `,
          type: 'default',
          onClick: () => {
            getAction(
              `/api/goods/farmFarmingExperience/${this.detail.upDown == 0 ? 'down' : 'up'}?id=${this.detail.id}`
            ).then((e) => {
              if (e.code == 200) {
                this.$message.success(`${this.detail.upDown == 0 ? '下架成功' : '上架成功'}`)
                setTimeout(() => {
                  this.$router.back()
                }, 500)
              } else {
                this.$message.error(e.msg)
              }
            })
          },
        },
        {
          name: '删除',
          popTitle: '确认删除吗?',
          isPop: true,
          type: 'danger',
          ghost: true,
          onClick: () => {
            api.command.del.call(this, {
              url: `/goods/farmFarmingExperience?id=${this.detail.id}`,
              isPost: false,
            })

            setTimeout(() => {
              this.$router.back()
            }, 500)
          },
        },
      ]
      return {
        left,
        right: this.detail.id && right,
      }
    },
  },
  render() {
    return (
      <DetailFormGroup
        foot={this.getFoot()}
        form={this.detail}
        typeData={{
          mainBodyName: this.storeData,
        }}
        data={[
          this.getForm1(),
          this.getForm2(),
          this.getForm22(),
          this.getForm23(),
          this.isShowSnapshot && this.getForm2Copy(),
          this.getForm3(),
          this.getForm4(),
          this.getForm6(),
        ]}
      />
    )
  },
}
</script>
<style lang="less" scoped>
.entityInformation-detail {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 30px;
}

/deep/.ant-input[type='number'] {
  -moz-appearance: textfield;
}
/deep/.ant-input[type='number']::-webkit-inner-spin-button,
/deep/.ant-input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
</style>
